.CardComponent {
    background-color: #d1d2d4;
    margin-top: 40px;
    min-height: 250px;
    width: 40%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.CardComponent-Title h3:hover,
.CardComponent-Title h3[data-hover] {
  color: black; 
}

.CardComponent:hover {
  background-color: #c7c6c6; 
}

.CardComponent-Text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
}