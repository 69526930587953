.UserComponent {
    background-color: white;
    /* margin-top: 15px; */
    margin: 8px;
    height: 50px;
    width: 70%;
    margin-left: 12%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.UserComponent-Link:hover h4 {
  color: black;
}
