.SearchComponent {
    display: flex;
    position: sticky;
    margin-top: 80px;
}

.SearchComponentForm {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
    padding: 5px;

}

.SearchComponent-SB {
    /* background-color: pink; */
    padding: 5px;
}


.SearchComponent-DP {
    /* background-color: green; */
    padding: 5px;
}


.SearchComponent-Btn {
    /* background-color: blue; */
    padding: 5px;
}


.SearchComponentButton {
    height: 30px;
    border-radius: 4px;
}

.SearchComponentButton:hover {
    background-color: #d1d2d4;
}

.state {
    width: 40px;
}