
.Home {
    width: 43%;
    margin-top: 200px;
    margin-left: 12%; 
}

.Home-Title {
  padding: 20px;
}

.Home-Link {
    padding: 5px;
}
