
.ChatIcon {
    /* font-size: 30px; */
    /* margin: 5px; */
    padding: 10px;
    width: 55px;
    height: 55px;
    /* border: 2px solid black; */
    border: 1px solid #ccc;
    border-radius: 50%;
    background: radial-gradient(circle, #f5f5f5, #e5e5e5);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
}

.ChatIcon-Icon {
  font-size: 30px;
  padding-top: 4px;
  padding-left: 1px;
  color: black;
}

.ChatIcon:hover {
  /* text-decoration: none;
  color: rgb(29, 27, 29); */
  /* font-size: 40px; */
  width: 70px;
  height: 70px;

}

.ChatIcon:hover .ChatIcon-Icon {
  font-size: 40px;
  padding-top: 6px;
  padding-left: 1px;
}

