
.App {
  text-align: center;
  /* justify-content: center; */
  /* background-color: orange; */
  height: 100vh;
  /* margin-top: 150px; */
  background-color: transparent;
  z-index: 1;

}

body {

  background-size: contain; 
  background-repeat: no-repeat;
  background-image: url("./images/skater-jam_02.svg");
  /* background-image: url("/skater-jam.svg"); */
  height: 100vh;
  background-position: right top; 
  background-attachment: fixed;
  overflow-x: hidden;
  /* overflow-y: hidden; */

}

.track-out {
  width: 4400px;
  height: 5400px;
  border: 5px solid black;
  border-radius: 50% 50%;
  background-color: transparent;
  position: absolute; 
  top: 130%; 
  left: -25%; 
  transform: translate(-50%, -50%);
  z-index: -1; 
}

/* .track-out {
  clip-path: circle(50% at 45% 45%); /* Example: Clip to a circle centered at 45% from the top and left */

.track-in {
  width: 2200px;
  /* height: 2000px; */
  height: 3800px;
  border: 5px solid black;
  border-radius: 50% 50%;
  background-color: transparent;
  position: absolute; 
  /* top: 240%;  */
  top: 130%; 
  /* left: 60%;  */
  left: -25%; 
  transform: translate(-50%, -50%);
  z-index: -1; 
}



 @media only screen and (max-width: 870px) {
  body {
    background-image: url("./images/group_01.svg");
    background-position: bottom;

  }
  
}

.ChatIcon {
  position: fixed;
  bottom: 30px;
  right: 20px;
}


/* body {
  background-image: url('data:images/utf8skater-3-poses_01.svg,<svg ...><g stroke="red" ... /></g></svg>');
} */

/* note that the fill color is the background color of the silhoutte not the background */
/* original page color pagecolor="%23ffffff" */
/* ornage fill color style="fill:%23000000 */
/* might be because of opacity but not sure  */
/* pagecolor doesnt seem to be changing this. you may have to try something else  style="fill:%231b78f6;fill-opacity:0.980392;*/

 /* body {
  background-color: red;

 } */